import styled from 'styled-components';
const MontserratRegular = 'Montserrat-Regular';
const MontserratSemiBold = 'Montserrat-SemiBold';
const BoldFont = 'Rubik-Medium';
const RegularFont = 'Rubik-Light';
import Scurve from '/public/images/WellnessPage/scurve.svg';
const MediumFont = 'Rubik-Regular';
const ExtraBoldFont = 'Rubik-Bold';
import {CustomListItem, MenuList} from '../NavigationBarV2/styles'
import {MenuLinks} from '../NavigationBarV2/styles';
import { TabsContainer } from "../WellnessPageV2/styles"

const ButtonWatchVideo = styled.button`
width: ${(props) => props.tryFree ? '190px' : '190px'};
height: 60px;
// background-color: ${(props) => props.tryFree ? '#fff' : ' #F6797D' };
color: ${(props) => props.tryFree ? '#1573B9' : '#9D87A9' };
display: ${({display}) => display}; 
padding: ${(props) => props.paddingVertical ? `${props.paddingVertical}` : '10px 10px'};
border-radius: 3px;
border: 2px solid #9D87A9;
font-size: 18px;
line-height: 21px;
font-family: Rubik-Medium;
font-style: normal;
text-transform: uppercase;
cursor: ${({ disabled }) => disabled ? 'not-allowed !important' : 'pointer'};
box-shadow: 4px 6px 5px -1px #0000001c;
.pink-triangle{
    width: 21px;
    height: 24px
    margin-right: 8%;
}
&:hover, &:focus, &:active {
  outline: none;
}
@media (max-width: 350px) {
  width: 59%;
  padding: 14px 10px;
}
@media (max-width: 720px) {
  width: 80%;
  width: 320px;
  height: 50px;
  margin-bottom: 5%;
}
@media (min-width: 1420px) {
  font-size: 18px;
  padding: 15px 10px;
}
@media (min-width: 1201px) {
  width: 36%;
  width: 190px;
  font-size: 18px;
  line-height: 21px;
  padding: 10px 10px;
  background: none;
  text-transform: uppercase;
}
`;

const  ButtonLearnMoree = styled.button`
width: ${(props) => props.tryFree ? '190px' : '190px'};
height: 60px;
background-color: ${(props) => props.tryFree ? '#fff' : ' #F6B479' };
color: ${(props) => props.tryFree ? '#1573B9' : '#fff' };
display: ${({display}) => display}; 
padding: ${(props) => props.paddingVertical ? `${props.paddingVertical}` : '10px 10px'};
border-radius: 3px;
border: none;
font-size: 18px;
line-height: 24px;
font-family: Rubik-Medium;
font-style: normal;
cursor: ${({ disabled }) => disabled ? 'not-allowed !important' : 'pointer'};
box-shadow: 4px 6px 5px -1px #0000001c;
@media (max-width: 1080px){
  // margin-left: 20px;
}
&:hover, &:focus, &:active {
  outline: none;
}
@media (max-width: 350px) {
  width: 59%;
  padding: 14px 10px;
}
@media (max-width: 720px) {
  width: 80%;
  width: 320px;
  height: 50px;
  margin-left: 0px;
}
@media (min-width: 1420px) {
  font-size: 18px;
  padding: 15px 10px;
}
@media (min-width: 1201px) {
  width: 36%;
  width: 190px;
  font-size: 18px;
  line-height: 21px;
  padding: 10px 10px;
  // margin-left: 5%;
  text-transform: capitalize;
}
`;

const TabData = styled.div`
  padding: 160px 0px 30px;
  width:100%;
  max-width: 1200px;
  display:flex;
  align-items:center;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  .responsiveWhyButton{
    display:none;
    >button{
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      width: 100%;
      margin-left: 0;
    }
  }
  >div:nth-child(2){
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    img {
        width: 80%;
    }
  }
  > div:last-child{
    float: left;
    display: flex;
    flex: 1;
    justify-content: flex-start;
    flex-direction: column;
    img {
      width: 13%;
      margin-left:16px;
      margin-bottom:10px;
    }
    > p:first-child{
        width:100%;
        font-style: normal;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: 0;
        color:#69C2FF;
        margin-top: 10px;
        padding-left: 19px;
        font-family: ${MontserratSemiBold};
     }
    > p {
        width:100%;
        font-style: normal;
        font-size: 18px;
        line-height: 26px;
        color: #24395a;
        margin-top: 10px;
        padding-left: 19px;
        font-family: ${MontserratRegular};
        @media(max-width: 500px){
          font-family: ${MontserratRegular};
          text-align: justify;
          padding-right: 12px;
          font-weight: 400;
          font-size: 15px;
          font-style: normal;
          line-height: 18px;
          padding-left: 0px;
          padding-right: 0px;
          text-align: left;
          margin-top: 0px;
        }
        @media(max-width: 435px){
          line-height: 18px;
        }
     }
    > h1 {
        font-size: 30px;
        margin-bottom: 25px;
        color: #24395a;
        font-family: Rubik-bold;
        font-style: normal;
        letter-spacing: 0;
        line-height: 36px;
        word-break: break-word;
        width: 100%;
        padding-left: 19px;
        text-transform: capitalize;
        margin-top: 0;
        @media(max-width: 500px){
          text-align: left;
          margin: 0;
        }
    } 
  }
  @media(max-width:1000px){
    >div{
      >h1{
    font-size: 24px;
      }
    }
  }
  @media(max-width:850px){
    flex-direction: column-reverse;
    padding: 0px 20px 80px;
    margin: 0;
    >div:nth-child(2){
      margin-top: 50px;
      >img{
        margin: 0;
        width: 100%;
      }
    }
    >div:last-child{
      width: 100%;
      text-align: center;
      display: flex;
      align-items: center;
      padding: 0 10px;
      margin-top: 60px;
      >p:first-child{
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        font-family: 'Montserrat-Regular';
        line-height: 19px;
        margin-bottom: 26px;
        text-align: left;
        padding-left: 0px;
        
      }
      >h1{
        padding: 0;
        font-weight: bold;
        font-size: 24px;
        line-height: 33px;
        text-align: left;
      }
      >img{
        margin-left: 0;
        margin-top: 25px;
        margin-bottom: 25px;
        margin-right: auto;
        width: 78px;
      }
      >p:last-child{
        font-weight: normal;
        font-size: 15px;
        line-height: 20px;
        padding: 0;
      }
      >button{
        display: none;
      }
  }
  .responsiveWhyButton{
    display: block;
    margin: 0 auto;
    width: 94%;
  }
}
@media(max-width:500px){
  >div{
    >h1{
      font-size: 13px;
      line-height: 19px;
    }
    >p{
      font-size: 11px;
      line-height: 16px;
    }
    p:last-child{
      font-weight: normal;
      font-size: 15px;
      line-height: 20px;
      padding: 0;
      text-align: justify;
    }
  }
  >div:last-child{
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    padding: 0 10px;
    margin-top: 56px;
  }
`;

const Button2 = styled.button`
width: ${(props) => props.tryFree ? '200px' : '190px'};
margin-top:20px;
  background-color: ${(props) => props.tryFree ? '#fff' : '#69C2FF' };
  color: ${(props) => props.tryFree ? '#1573B9' : '#fff' };
  display: ${({display}) => display}; 
  padding: ${(props) => props.paddingVertical ? `${props.paddingVertical}` : '12px 10px'};
  border-radius: 3px;
  border: none;
  font-size: 16px;
  font-family: Rubik-Medium;
  font-style: normal;
  line-height: 21px;
  margin-left: 19px;
  cursor: ${({ disabled }) => disabled ? 'not-allowed !important' : 'pointer'};
  box-shadow: 4px 6px 5px -1px #0000001C;
  &:hover, &:focus, &:active {
    outline: none;
  }
  
  @media (max-width: 1000px) {
   margin-top: ${(props) => props.tryFree ? props.marginTop : '50px'};
  }
`;

const Section = styled.div`
  margin: 0 auto;
  height: 530px;
  width: 100%;
  max-width: 1200px;
  display: block;
  font-family: ${RegularFont};
  display: ${({intelligenceSection}) => intelligenceSection ? 'block' : 'flex'};
  align-items: flex-start;
  position: relative;
  padding-top: 84px;
  margin-bottom: -70px;
  @media (max-width: 991px) {
    display: block;
  }
  @media (max-width: 1201px) {
    padding-top: 0px;
  }
  @media (max-width: 930px) {
    flex-flow: column;
    height: 1100px;
    padding-top: 30px;
  }
  @media(max-width: 700px){
    margin-bottom: 0;
    height: auto;
  }
  @media(max-width: 2900px){
    img{
      height: 0px;
      width: 0px;
    }
  }
  @media(max-width: 1000px){
    img{
      width: 100%;
      height: auto;
    }
  }
`;

const  IntroTextContainer = styled.div`
  display: inline-block;
  float: left;
  width: 50%;
  letter-spacing: 1.5px;
  padding: 0px 10px 30px 0px;
  h1 {
     margin-bottom: 25px;
     font-family: ${BoldFont};
     letter-spacing: 0;
     margin-top: 0px;
     font-weight: 700;
     color: rgb(36, 57, 90);
     font-size: 42px;
     line-height: 50px;
     font-weight: 600;
     text-align: left;
     text-transform: uppercase;
  }
  p {
    line-height: 22px;
    font-family: ${MontserratRegular};
    letter-spacing: 0;
    font-weight: 500;
    margin: 25px 0 35px 0;
    color: rgb(36, 57, 90);
    font-size: 18px;
    line-height: 31px;
  }
  .wrap {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .divider{
    width: 75px;
    >img{
      width: 100%;
      height: 5px;
    }
  }
  @media (max-width: 1201px) {
    width: 80%;
    padding: 0px 10px 30px 80px; 
    
    h1{
      margin-bottom: 25px;
      font-family: ${BoldFont};
      letter-spacing: 0;
      margin-top: 10px;
      font-weight: 700;
    //   text-transform: uppercase;
      color: rgb(36, 57, 90);
      font-size: 36px;
      line-height: 50px;
      font-weight: 600;
      text-align: left;
    }
    p {
    line-height: 22px;
    font-family: ${MontserratRegular};
    letter-spacing: 0;
    font-weight: 500;
    margin: 30px 0 30px 0;
    color: rgb(36, 57, 90);
    font-size: 16px;
    line-height: 31px;
    }
    .wrap {
      width: 60%;
    }
  }
  @media (max-width: 930px) {
    width: 100%;
    .wrap {
      // width: 45%;
      width: 80%;
    }
  }
  @media (max-width: 720px) {
    width: 100%;
    text-align: left;
    >div:last-child{
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }
  @media(max-width: 350px){
    >div:last-child{
      >button{
        width: 80%;
      }
    }
  }
  @media (max-width: 600px) {
      padding-top: 10px;
      height: 350px;
      .wrap {
        width: 100%;
      }
  }
  @media(max-width: 500px){
    padding: 10px;
    width: 100%;
    max-width: 750px;
    min-width: 350px;
    >h1{
      font-size: 36px;
      font-family: 'Rubik-Bold';
      font-style: normal;
      font-weight: bold;
      line-height: 110.5%;
      margin-left: 20px;
      margin-bottom: 30px;
      margin: 0px 20px 25px 20px;
    }
    .divider{
      margin-left: 20px;
    }
    >p{
      font-weight: 500;
      font-size: 15px;
      line-height: 26px;
      margin: 15px;
      text-align: justify;
font-family: Montserrat-Medium;
font-style: normal;
font-weight: 500;
line-height: 22px;
margin-top: 27px;
margin-bottom: 35px;
    }
  }
  @media (max-width: 350px) {
    .wrap {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  
  @media (min-width: 1420px) {
    h1 {
      max-width: unset;
      letter-spacing: 1px;
      text-align:unset;
      font-family: ${BoldFont};
      letter-spacing: 0;
      font-weight: 700;
    //   text-transform: uppercase;
      color: rgb(36, 57, 90);
      font-size: 42px;
      line-height: 50px;
      font-weight: 600;
      text-align: left;
      letter-spacing: 1.5px;
    }
    p {
    line-height: 22px;
    font-family: Montserrat-Medium;
    letter-spacing: 0;
    font-weight: 500;
    margin: 25px 0 35px 0;
    color: rgb(36, 57, 90);
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 1.5px;
    }
    .wrap{
      margin-top: 20px;
    }
  }
  @media (min-width: 1500px) {
    padding: 0px 10px 0px 0px;
  }
`;
const PosterImage = styled.div`
  display: inline-block;
  float: left; 
  padding: 0 0px 0 0px;
  width: 60%;
  text-align: center;
  img {
    width: 100%;
    margin-bottom: -35px;
    max-height: 800px;
    min-height: 420px;
    position: relative;
    height: 500px;
    width: auto;
    top: -52.5px;
    float: right;
  }
  
  @media (max-width: 1201px) {
    width: 50%;
    padding: 0px;
    
    img {
      width: 100%;
      margin-bottom: -70px;
      min-height: unset;
      max-height: unset;
      max-height: 800px;
    min-height: 420px;
    position: relative;
    height: 450px;
    width: auto;
    top: 37px;
    left: -70px
  }
  }
  
  @media (max-width: 1000px) {
    img {
      display: none;
    }
  }
  @media (max-width: 930px) {
    width: 100%;
    padding: 70px 30px 0px 30px;
  }
  @media (max-width: 768px) {
    padding: 30px 30px 0px 30px;
    img {
      top: 90px;
      position: relative;
      margin-bottom: -15px;
    }
  }
  @media (max-width: 600px) {
    padding: 0px 30px 0px 30px;
    img {
      top: 25px;
      position: relative;
      margin-bottom: -15px;
    }
  }
  @media (max-width: 500px) {
    img {
      margin-bottom: -50px;
      min-height: 250px;
      height: 200px;
      position: relative;
    margin: 0px;
    top: 202px;
    height: 200px;
    margin-bottom: 200px;
    left: -20px;
    top: 70px;
    }
  }
  @media (min-width:1400px){
    >img{
      max-height: 650px;
    }
  }
  @media(max-width: 400px){
    padding: 0px 30px 0px 30px;
    margin-top: 20px;
  }
`;

const ResourcesWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  padding-bottom: 0px;
  float:left;
  @media(max-width: 800px){
    padding: 0 10px;
  }
`;
const RowWrapperV2 = styled.div`
  width: 100%;
  padding: ${({selected}) => selected ? '25px 20px' : '15px 20px'};
  background-color: ${({selected, bgColor}) => selected ? bgColor : '#fff'};
  display: flex;
  flex-direction: column;
  border-radius: 7px;
  cursor: pointer;
  margin-bottom: 10px;
  .header{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  >img{
  width: 30px;
  height: 30px;
  margin-right: 10px;
  cursor: pointer;
  }
  >div{
  width: calc(100% - 70px);
  font-size: 22px;
  font-family: ${({customColor}) => customColor ? `${BoldFont}`: `${MediumFont}`} ;
  color: #24395A;
  >h3{
    margin:0;
  }
  }
  .downArrow{
  width: 20px;
  transform: ${({selected}) => selected && 'rotateX(180deg)'};
  >img{
  width: 14px;
  cursor: pointer;
  }
  }
  }
  .expand{
  padding: 20px 50px 0;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 26px;
  font-family: ${MontserratRegular};;
  font-style: normal;
  color: #3A3A3A;
  cursor: pointer;
  }
  @media(max-width:500px){
  display:none;
  }
`;
const Resources = styled.div`
  float: left;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top:30px;
  flex-direction:${({flexDirection}) => flexDirection};
  justify-content: space-between;
  margin-bottom: 150px;
  >div:first-child{
    .responsive{
      display:none;
    }
  }
  > div {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    >div:first-child{
      display: flex;
      align-items: center;
      padding-top: 20px;
      padding-bottom: 35px;
      flex-direction: row;
      @media (max-width: 500px) {
        padding-top: 41px;
        padding-bottom: 35px;
      }
      .title{
        font-size: 30px;
        color: ${({col})=>col};
        font-family: Rubik-Medium;
        font-style: normal;
        line-height: 58px;
        letter-spacing: 0;
        display:inline;
        word-break: break-word;
        width: 100%;
        padding-left: 19px;
        @media (max-width: 700px){
          font-size: 24px;
        }
      } 
      >img{
        width: 60px;
        height: 60px;
        @media (max-width: 700px){
          margin-left: 20px;
        }
      }
    }
    >div{
      width: 100%;
      display: flex;
      flex-direction: column;
      cursor:pointer;
    }
    >div:nth-child(3){
      >div{
        >div:last-child{
          >img{
            width:100%;
          }
        }
      }
    }
  }
  > div:last-child {
    width: 100%;
    align-items:${({align}) => align};
    img {
        float: left;
        width: 500px;
        height: auto;
    }
  }
  @media (max-width: 1000px) {
    flex-direction: column;
    > div {
        width: 100%;
        padding: 0 62px;
        margin-bottom: 20px;
    }
    > div:last-child{
        width: 100%;
        margin-bottom: 0px;
        margin-top: 30px;
        align-items: center;
        img {
          //  width: 200px;
           display: flex;
           margin: 0 auto;
           float: unset;
        }
    }
  }
  @media (max-width:600px){
    padding:0;
    margin-top: 10px;
    margin-bottom: 25px;
    >div:first-child{
      padding: 0;
      .normal{
        display:none;
      }
      .responsive{
        display:block;
      }
    }
    >div:last-div{
      display:none;
    }
    .image{
      display:none;
    }
    >div:first-child{
      >div:last-child{
        >div{
          >div{
            .responsive-image{
              >img{
                width:100%;
              }
            }
          }
        }
      }
    }
  }
`;

const ResourcesV2 = styled.div`
  float: left;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top:30px;
  flex-direction:${({flexDirection}) => flexDirection};
  justify-content: space-between;
  margin-bottom: 114px;
  >div:first-child{
    .responsive{
      display:none;
    }
  }
  > div {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    >div:first-child{
      display: flex;
      align-items: center;
      padding-top: 20px;
      padding-bottom: 35px;
      flex-direction: row;
      @media (max-width: 500px) {
        padding-top: 0px;
        padding-bottom: 0px;
      }
      .title{
        font-size: 30px;
        color: ${({col})=>col};
        font-family: Rubik-Medium;
        font-style: normal;
        line-height: 58px;
        letter-spacing: 0;
        display:inline;
        word-break: break-word;
        width: 100%;
        padding-left: 19px;
        @media (max-width: 700px){
          font-size: 24px;
        }
      } 
      >img{
        width: 60px;
        height: 60px;
        @media (max-width: 700px){
          margin-left: 20px;
        }
      }
    }
    >div{
      width: 100%;
      display: flex;
      flex-direction: column;
      cursor:pointer;
    }
    >div:nth-child(3){
      >div{
        >div:last-child{
          >img{
            width:100%;
            margin-top: 25px;
          }
        }
      }
    }
  }
  > div:last-child {
    width: 100%;
    align-items:${({align}) => align};
    img {
        float: left;
        width: auto;
        height: 400px;
    }
  }
  @media (max-width: 1000px) {
    flex-direction: column;
    > div {
        width: 100%;
        padding: 0 62px;
        margin-bottom: 20px;
    }
    > div:last-child{
        width: 100%;
        margin-bottom: 0px;
        margin-top: 30px;
        align-items: center;
        img {
          //  width: 200px;
           display: flex;
           margin: 0 auto;
           float: unset;
        }
    }
  }
  @media (max-width:600px){
    padding:0;
    margin-top: 10px;
    margin-bottom: 25px;
    >div:first-child{
      padding: 0;
      .normal{
        display:none;
      }
      .responsive{
        display:block;
      }
    }
    >div:last-div{
      display:none;
    }
    .image{
      display:none;
    }
    >div:first-child{
      >div:last-child{
        >div{
          >div{
            .responsive-image{
              >img{
                width:100%;
              }
            }
          }
        }
      }
    }
  }
`;

const MainResources = styled.div`
  width:100%;
  position:relative;
  float: left;
  .line{
    position: absolute;
    left:0px;
    >svg{
      fill: ${({color}) => color};
    }
  }
  >h1{
    width: 65%;
    display: block;
    font-size: 36px;
    text-transform: capitalize;
    color: #24395A;
    line-height: 36px;
    font-style: normal;
    font-family: Rubik-Medium;
  }
  >h3{
    width: 65%;
    display: block;
    font-size: 36px;
    text-transform: capitalize;
    color: #24395A;
    line-height: 36px;
    font-style: normal;
    font-family: Rubik-Medium;
  }
  .scurve{
    float: left;
    width: 100%;
    background-image: url(${Scurve});
    background-repeat: no-repeat;
    background-size: 100% 100%;
    transform: translate(-90px, 90px);
    @media(max-width: 1400px){
      transform: translate(-20px,90px);
    }
  }
  @media(max-width: 500px){
    >h1{
      font-weight: 500;
      font-size: 26px;
      width: 100%;
      margin: 0 20 20px 20;
      padding-left: 20px;
      margin-left: 10px;
      text-align: left;
      font-family: 'Rubik-Medium';
      padding-left: 0px;
    }
    .line{
      padding-left: 20px;
    }
  }
`;

const RowResponsiveWrapperV2 = styled.div`
  display: block;
  width: 100%;
  padding: ${({selected}) => selected ? '25px 20px' : '15px 20px'};
  background-color: ${({selected, bgColor}) => selected ? bgColor : '#fff'};
  flex-direction: column;
  border-radius: 7px;
  cursor: pointer;
  margin-top: 15px;
  .header{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    >img{
      width: 35px;
      height: 40px;
      margin-right: 10px;
      cursor: pointer;
    }
    >div{
      width: calc(100% - 70px);
      font-size: 24px;
      font-family: ${({customColor}) => customColor ? `${BoldFont}`: `${MediumFont}`};
      color: #24385A;
    }
    .downArrow{
      width: 20px;
      transform: ${({selected}) => selected && 'rotateX(180deg)'};
      >img{
        width: 14px;
        cursor: pointer;
      }
    }
  }
  .expand{
    padding: 20px 30px 0 50px;
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 26px;
    font-family: ${MontserratRegular};
    cursor: pointer;
    @media(max-width: 700px){
      padding: 20px 0px 0 0px;
    }
    @media(max-width: 435px){
      font-size:15px;
      
    }
  }
  @media(max-width: 500px){
    display: flex;
    .header{
      >div{
      font-size: 22px;
      }
    }
  }
`;

const VideoButtonV1 = styled.button`
  width: 59%;
  background-color: transparent;
  color: #1573B9;
  padding: 13px 10px;
  border-radius: 40px;
  border: 1px solid #1573B9;
  font-size: 13px;
  font-family: ${BoldFont};
  font-weight: 700;
  display: none;
  
  &:hover, &:focus, &:active {
    outline: none;
  }
  
  i {
    margin-right: 10px;
  }
  @media (max-width: 350px){
    margin-top: 15px;
  }
  @media (min-width: 1420px) {
    font-size: 15px;
    padding: 15px 10px;
  }
  @media (max-width: 500px) {
    width: 315px;
    margin-left: 2px;
  }
`;

const CoreFeatureSectionV1 = styled.div`
  width: 100%;
  max-width: 1200px;
  display: flex;
  // padding-top: 100px;
  padding-bottom:50px;
  // margin-top: 90px;
  >div{
      width:100%;
      justify-content:center;
      text-align: center;
    >p{
      color:#F6797D;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      letter-spacing: 0;
      font-family: ${MontserratSemiBold};
    }
    >h1{
      margin-top:20px;
      margin-bottom:20px;
      font-size: 40px;
      line-height: 48px;
      letter-spacing: 0.03em;
      color: #24395A;
      text-align: center;
      font-style: normal;
      font-family: Rubik-bold;
      text-transform: capitalize;
    }
    >h2{
      margin-top:20px;
      margin-bottom:20px;
      font-size: 40px;
      line-height: 48px;
      letter-spacing: 0.03em;
      color: #24395A;
      text-align: center;
      font-style: normal;
      font-family: Rubik-bold;
      text-transform: capitalize;
    }
    .middle-line-textt{
      font-family: 'Montserrat-Regular';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      color: #24395A;
      margin-bottom: 25px;
    }
  }
  @media(max-width: 600px){
    padding-top: 0;
    >div{
      text-align: center;
      >p{
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
      }
      >h1{
        font-weight: bold;
        font-size: 36px;
        line-height: 41px;
        font-style: normal;
        font-size: normal;
        font-family: 'Rubik-Bold';
        font-weight: 700;
        line-height: 41px;
        word-spacing: 10px;
        // margin-top:70px
        padding: 0px 7px;
      }
    }
  }
`;

const CoreFeatureSectionV2 = styled.div`
  width: 100%;
  max-width: 1200px;
  display: flex;
  // padding-top: 100px;
  padding-bottom:25px;
  margin-top: 180px;
  >div{
      width:100%;
      justify-content:center;
      text-align: center;
    >p{
      color:#F6797D;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      letter-spacing: 0;
      font-family: ${MontserratSemiBold};
    }
    >h1{
      margin-top:20px;
      margin-bottom:20px;
      font-size: 40px;
      line-height: 48px;
      letter-spacing: 0.03em;
      color: #24395A;
      text-align: center;
      font-style: normal;
      font-family: Rubik-bold;
      text-transform: capitalize;
    }
    >h2{
      margin-top:20px;
      margin-bottom:20px;
      font-size: 40px;
      line-height: 48px;
      letter-spacing: 0.03em;
      color: #24395A;
      text-align: center;
      font-style: normal;
      font-family: Rubik-bold;
      text-transform: capitalize;
    }
    .middle-line-textt{
      font-family: 'Montserrat-Regular';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      color: #24395A;
      margin-bottom: 25px;
      @media(max-width: 600px){
        padding-left: 16px;
        padding-right: 16px;
      }
    }
  }
  @media(max-width: 600px){
    padding-top: 0;
    margin-top: 50px;
    >div{
      text-align: center;
      >p{
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
      }
      >h1{
        font-weight: bold;
        font-size: 36px;
        line-height: 41px;
        font-style: normal;
        font-size: normal;
        font-family: 'Rubik-Bold';
        font-weight: 700;
        line-height: 41px;
        word-spacing: 10px;
        // margin-top:70px
        padding: 0px 7px;
      }
    }
  }
`;

const TextImagesContainer = styled.div`
    margin: 0 auto;
    width: 100%;
    // display: flex;
    // height: 640px;
    margin-top: 160px;
    max-width: 1200px;
    align-items: center;
    position: relative;
    margin-bottom: 60px;
    justify-content: space-around;
    background: rgba(105, 194, 255, 0.05);
    // flex-direction: column;
    // justify-content: center;
    @media(max-width: 700px){
      margin-bottom: 30px;
      margin-top: 50px;
    }
    .text-wrapper{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .top-text{
        font-family: 'Rubik-Medium';
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 58px;
        text-align: center;
        letter-spacing: 0.03em;
        text-transform: capitalize;
        padding-top: 75px;
        color: #24395A;
      }
      .lower-text{
        font-family: 'Montserrat-Medium';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        margin-top: 25px;
        color: #253A5B;
        @media(max-width: 700px){
          padding-left: 16px;
          padding-right: 16px;
        }
      }
      .blue-linee{
        width: 80px;
        height: 6px;
        margin-top: 30px;
      }
    }
    .box-wrapper{
      margin-top: 50px;
      display: flex;
      justify-content: space-around;
      padding-bottom: 75px;
      @media (max-width: 1050px){
        flex-direction: column;
        align-items: center;
        padding-bottom: 25px;
      }
      .first-box{
        width: 350px;
        // height: 300px;
        background: #FFFFFF;
        border: 1px solid #69C2FF;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @media(max-width: 1050px){
          margin-bottom: 25px;
        }
        .box-img{
          margin-top: 40px;
        }
        .box-first-text{
          font-family: 'Rubik-Medium';
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 28px;
          text-transform: capitalize;
          color: #243A5A;
          text-align: center;
          margin-top: 22px;
        }
        .box-last-text{
          font-family: 'Montserrat-Regular';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: #253A5B;
          margin-top: 19px;
          margin-bottom: 39px;
        }
      }
    }
`;

const CoreWrapper = styled.div`
width: 100%;
  // max-width: 1284px;
  max-width: 1200px;
  margin: 150px 0 130px 0;
  margin-left: auto;
  margin-right: auto;  
  //background-color: red;
  // float: left;
  >div:first-child{
    text-align:center;
    margin-bottom: 75px;
    >p{
      font-style: normal;
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      letter-spacing: 0;
      color: #69C2FF;
      font-family: ${MontserratSemiBold}
    }
    >h1{
      font-family: ${ExtraBoldFont};
      font-size: 40px;
      line-height: 48px;
      text-align: center;
      letter-spacing: 0.03em;
      text-transform: capitalize;
      color: #24395A;
    }
    .second-line{
      font-family: 'Montserrat-Regular';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-align: center;
      text-transform: capitalize;
      color: #24395A;
      justify-content: center;
    }
  }
  >div:nth-child(3){
    width:100%;
    // padding: 0px 80px 0px 80px;
    padding: 10px
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    background-color: rgba(105, 194, 255, 0.1);;
    >div:first-child{
      width:75%;
      margin-top: 75px;
      //padding: 0 7px 50px;
      >h1{
        font-family: ${BoldFont};
        font-size: 30px;
        line-height: 54px;
        letter-spacing: 0;
        color: #69c2ff;
        margin: 0;
      }
      >h2{
        font-family: ${MontserratSemiBold};
        margin: 0px 0 30px;
        font-style: normal;
        font-size: 24px;
        line-height: 29px;
        color: #24395a;
        text-transform: uppercase;
      }
      >img{
        margin-bottom: 30px;
      }
      >div{
        height: auto;
        >p{
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 26px;
          color: #3A3A3A;
          font-family: ${MontserratRegular};
          text-align: center;
          margin-bottom: 56px;
        }
        .box-top-line{
          font-family: 'Rubik-Medium';
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          text-align: center;
          color: #253A5B;
        }
        .box-last-line{
          font-family: 'Montserrat-Regular';
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 24px;
          text-align: center;
          color: #253A5B;
          margin-top: 15px;
        }
      }
    }
    >div:last-child{
      width:auto;
      height: 350px;
      margin-top: 66px;
      display: flex;
      justify-content: center;
      >img{
        position: relative;
        top: -36px;
        width: auto;
        height: 400px;
      }
    }
  }
  .responsive-core{
    display: none;
    width: 100%;
    padding: 0px 70px 0px 110px;
    flex-direction: column;
    >div{
      width: 100%;
      padding: 0;
      >div:first-child{
        display: flex;
        width: 100%;
      }
      >div:nth-child(2){
        width: 100%;
        >div:first-child{
          margin-bottom: 60px;
          width: 100%;
        }
        >div:last-child{
          width: 100%;
          margin-left: -10px;
          text-align: center;
          display: flex;
          justify-content: center;
          >img{
            // width: auto;
            width: 100%;
            // height: 450px;
            height: auto;
          }
        }
      }
      >div:last-child{
        width:100%;
        text-align:center;
      }
    }
  }
  @media(max-width:1000px){
    .responsive-core{
      >div{
     
          >div:nth-child(2){
            >div:first-child{
              // margin-bottom: 20px;
              margin-bottom: 0px;
            }
    
        }
      }
    }
    >div:nth-child(3){
      >div:first-child{
        padding: 0 50px;
        >button{
          margin-top: 30px;
          width: 60%;
        }
      }
      >div:last-child{
        >img{
          width:80%;
        }
      }
    }
  }
  @media(max-width: 720px){
    margin: 0;
    .responsive-core{
      display: flex;
      >div{
        .selectedCoreContent{
          // margin-left: 10px;
          padding: 20px;
          background: rgba(246, 121, 125, 0.1);
          >div:first-child{
            >button{
              margin-top: 30px;
            }
          }
        }
        .coreDivider{
          >img{
            width: 100%;
          }
        }
      }
    } 
    >div:nth-child(2){
      display:none;
    }
    >div:nth-child(3){
      display:none;
    }
  }
  @media(max-width: 500px){
    margin-top: 60px;
    padding: 0px 20px;
    >div:first-child{
      margin-bottom: 0px;
      >p{
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.08em;
      }
      >h1{
        margin-bottom: 30px;
        font-family: Rubik;
font-style: normal;
font-weight: bold;
font-size: 22px;
line-height: 30px;
text-align: center;
letter-spacing: 0.03em;
text-transform: capitalize;
color: #24395A;
      }
    }
    .responsive-core{
      padding: 0 10px;
      padding-top: 30px;
      >div{
        >div{
          h1{
            font-size: 20px;
            letter-spacing: -0.025em;
            line-height: 24px;
            font-family: Rubik;
font-style: normal;
font-weight: bold;
font-size: 20px;
line-height: 24px;
text-align: left;
letter-spacing: -0.025em;
          }
        }
      }
      .selectedCoreContent{
        >div:first-child{
          >h2{
            font-size: 16px;
            line-height: 20px;
            margin-top: 0;
            font-family: Montserrat;
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 20px;
          }
          >img{
            display: none;
          }
          >div{
            >p{
              line-height: 18px;
              // margin-top: 33px;
              font-family: Montserrat-Regular;
font-style: normal;
font-weight: normal;
font-size: 15px;
line-height: 18px;
text-align: left;
color: #253A5B;
            }
            .
          }
        }
      }
    }
  }
  @media(max-width: 360px){
    margin-top: 60px;
    padding: 0px 20px;
    >div:first-child{
      >h1{
        font-size: 21px
      }
    }  
`;

const WellBeingg = styled.div`
  width:100%;
  max-width: 1200px;
  display:flex;
  height: 400px;
  padding: 0 40px 0p 40px;
  background-repeat: no-repeat;
  border-radius: 12px;
  justify-content: center;
  margin: 150px auto 70px auto;
  @media(max-width: 500px){
    display: none;
  }
  @media(max-width: 780px){
    display: none;
  }
  >div{
    width:50%;
    display: flex;
    align-items: center;
    justify-content:flex-end;
    height: 400px;
  }
  >div:first-child{
    justify-content:flex-start;
    height: 400px;
    background: #F6B479;
    border-radius: 16px 0px 0px 16px;
    display: block;
    @media(max-width: 500px){
      display: none;
    }
    >div:first-child{
    >p{
      left: 258px;
      display: flex;
      font-family: ${BoldFont};
      font-style: normal;
      font-size: 42px;
      line-height: 49px;
      color: #FFFFFF;
      margin-bottom: 30px;
      padding: 61px 0px 30px 92px;
      @media(max-width: 500px){
        display: none;
      }
    }
  }
}
>div:last-child{
  display: flex;
  border-radius: 0px 16px 16px 0px;
  background: #F6B479;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;
    @media(max-width: 500px){
      display: none;
    }
    >img{
      width: auto;
      height: 370px;
      position: relative;
      top: 24px;
    }
}
@media (max-width: 500px){
  width:100%;
  display: grid;
  height: 400px;
  padding: 0 80px 0p 80px;
  background-repeat: no-repeat;
  border-radius: 12px;
  justify-content: center;
  margin: 160px auto 160px auto;
  margin-bottom: 500px
  >div{
    width:100%;
    display: flex;
    align-items: center;
    justify-content:flex-end;
    height: 400px;
  }
  >div:first-child{
    width: 100%;
    justify-content:flex-start;
    height: 400px;
    background: #F6B479;
    display: block;
    >div:first-child{
    >p{
      left: 258px;
      display: flex;
      font-family: ${BoldFont};
      font-style: normal;
      font-size: 42px;
      line-height: 49px;
      color: #FFFFFF;
      margin-bottom: 30px;
      padding: 61px 0px 30px 92px;
      width: 100%;
      padding: 50px 0px;
      text-align: center;
    }
  }
}
>div:last-child{
  height: 400px;
  display: flex;
  background: #F6B479;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;
    top: -2px;
    position: relative;
    >img{
      width: 350px;
    height: auto;
    position: relative;
    top: 65px;
    }
}
}
@media (max-width: 780px){
  width:100%
  display: grid;
  height: 400px;
  padding: 0 80px 0p 80px;
  background-repeat: no-repeat;
  border-radius: 12px;
  justify-content: center;
  margin: 0px auto 160px auto;
  margin-bottom: 250px
  >div{
    width:100%;
    display: flex;
    align-items: center;
    justify-content:flex-end;
    height: 400px;
  }
  >div:first-child{
    width: 100%;
    justify-content:flex-start;
    height: 400px;
    background: #F6B479;
    display: block;
    max-width: 750px;
    width: 700px;
    min-width: 500px;
    >div:first-child{
    >p{
      left: 258px;
      display: block;
      font-family: ${BoldFont};
      font-style: normal;
      font-size: 36px;
      line-height: 49px;
      color: #FFFFFF;
      margin-bottom: 30px;
      padding: 61px 0px 30px 92px;
      width: 100%;
      padding: 50px 0px;
      text-align: center;
    }
  }
}
>div:last-child{
  height: 260px;
  display: flex;
  background: #F6B479;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;
    top: -2px;
    position: relative;
    >img{
      width: 315px;
    height: auto;
    position: relative;
    top: 10px;
    }
}
}
`;

const ButtonGetStartedd = styled.button`
  width: ${(props) => props.tryFree ? '200px' : '330px'};
  background-color: ${(props) => props.tryFree ? '#fff' : '#253A5B' };
  color: ${(props) => props.tryFree ? '#1573B9' : '#fff' };
  display: ${({display}) => display}; 
  padding: ${(props) => props.paddingVertical ? `${props.paddingVertical}` : '15px 0px'};
  border-radius: 3px;
  border: none;
  font-size: 18px;
  font-family: Rubik-Medium;
  font-style: normal;
  margin-left: 80px;
  cursor: ${({ disabled }) => disabled ? 'not-allowed !important' : 'pointer'};
  margin-bottom: 20px;
  &:hover, &:focus, &:active {
    outline: none;
  }
  
  @media (max-width: 1000px) {
   margin-top: ${(props) => props.tryFree ? props.marginTop : '35px'};
  }
  @media (max-width: 780px) {
    margin: auto;
  }
  @media (max-width: 500px) {
    width: 315px;
    margin: auto;
  }
`;

const CoreListt = styled.div`
  width:100%;
  padding: 0;
  >div:first-child{
    cursor: pointer;
    >h1{
      width: 90%;
      color: ${({active})=>active ? '#F6797D' : "#24395A" };
      font-family: ${({active})=>active ? `${ExtraBoldFont}` : `${MediumFont}`};
      margin-left: 15px;
    }
    >img{
      height: 10%;
      margin: auto 0;
      transform: ${({active}) => active && 'rotateX(180deg)'};
      margin-right: 15px;
    }
  }
`;

const CustomListt = styled(CustomListItem)`
    width: auto;
    display: flex;
    justify-content: center;
    color: #69c2ff;
    margin: 0px 25px 0px 25px;
    border-bottom: ${({ active }) => active ? '5px solid #69c2ff' : '0px solid #EDEDED'};
    position: relative;
    top: 3px;
    >a{
      font-family : 'Rubik-Medium';
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      font-weight: normal;
      color: ${({active})=>active ? '#69c2ff' : "#9C9C9C" };
    }
    &:hover, &:focus, &:active {
      color: #69c2ff;
      border-bottom: 5px solid #69c2ff;
      // background: radial-gradient(100% 100% at 50% 0%, rgba(105, 194, 255, 0.44) 0%, rgba(173, 221, 255, 0.44) 23.75%, rgba(220, 241, 255, 0.44) 44.58%, rgba(255, 255, 255, 0) 70.62%);
      > a {
        color: #69c2ff;
      }
    }
    @media(max-width: 1200px) {
      margin: 0px 2%;
      border-left: none;
      border-bottom: ${({ active }) => active ? '5px solid #69c2ff' : '0px solid #EDEDED'};
      &:hover, &:focus, &:active {
        border-left: none;
        border-bottom: ${({ active }) => active ? '5px solid #69c2ff' : '3px solid #EDEDED'};
      }
    }
    ul > li:hover {
      color: #69c2ff;
    }
`;

const MenuLinkssV1 = styled(MenuLinks)`
font-family: 'Rubik-Medium';
font-size: 20px;
line-height: 24px;
text-align: center;
letter-spacing: -0.025em;
color: #69c2ff;
`;

const MenuListV2Main3 = styled(MenuList)`
display: flex;
align-items: center;
width: 100%;
justify-content: space-between;
border-bottom: 3px solid #EDEDED;
padding: 0px;
@media (max-width: 1200px) {
  position: unset;
  width: 100%;
  box-shadow: none;
  background-color: none;
}`;

const TabsContainerV3 = styled(TabsContainer)`
  @media(max-width: 720px){
    display:none;
  }
  .background_color{
    background: radial-gradient(100% 100% at 50% 0%, rgba(105, 194, 255, 0.44) 0%, rgba(173, 221, 255, 0.44) 23.75%, rgba(220, 241, 255, 0.44) 44.58%, rgba(255, 255, 255, 0) 70.62%);
  }
`;

const  TopCard = styled.div`
  max-width:100%;
  margin:auto;
.topcard-demo-wrapper{
  width:100%;
  display:flex;
  justify-content:center;
  @media (max-width: 1000px){
    padding: 25px;
  }
  .topcard-demo{
    width:100%;
    max-width: 1200px;
    // height: 200px;
    border-radius: 6px;
    display:flex;
    margin-top:20px;
    background: linear-gradient(90deg, #102B52 29.41%, #006FBC 100%);
    margin-bottom: 25px;
    @media(max-width: 1000px){
      display: flex;
      flex-wrap: wrap;
      height: 100%;
    }
  .topcard-pic{
    margin-top:15px;
    margin-bottom:15px;
    margin-left:15px;
    @media(max-width: 1000px){
      width: 100%;
      height: 100%;
      margin: 0px;
    }
    .enter-img{
      @media(max-width: 1000px){
      width: 90%;
      display: flex;
      margin: auto;
      height: 90%;
      margin-top: 15px;
      }
    }
  } 
  .topcard-content{
    @media (max-width: 1000px){
      width: 100%
    }
    .upper-content{
      color: #F6B479;
      font-family: 'Rubik-Medium';
      font-style: normal;
      font-size: 24px;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-align: center;
      text-transform: capitalize;
      margin-top:38px;
      margin-left:25px; 
      @media (max-width: 1000px){
        margin: auto;
        justify-content: center;
        margin-top: 15px;
      }
    }
    .lower-content{
      font-family: 'Montserrat-Regular';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      text-transform: capitalize;     
      color: #FFFFFF;
      margin-top:25px;
      margin-bottom:37px;
      margin-left:25px;
      @media (max-width: 1000px){
        width: auto;
        display: block;
        text-align: center;
        margin: auto;
        margin-top: 15px;
        font-size: 13px;
        display: none;
      }
      @media (max-width: 400px){
        font-size: 12px;
      }
      @media (max-width: 360px){
        font-size: 11px;
      }
      >p{
        margin-bottom:2px;
      } 
    }
    .lower-mobb-content{
      width: auto;
      display: block;
      text-align: center;
      margin: auto;
      margin-top: 15px;
      font-size: 16px;
      font-family: 'Montserrat-Regular';
      text-transform: capitalize;     
      color: #FFFFFF;
      padding-left: 10px;
      padding-right: 10px;
      @media(min-width: 1200px){
        display: none;
      }
    }
  }
  .demo-btn{
    width: 250px;
    height: 60px;
    background: #F6B479;
    box-shadow: 9px 12px 13px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    margin: auto;
    margin-right: 32px;
    border: none;
    @media (max-width: 1000px){
      margin: auto;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .watch-text{
      width: 196px;
      height: 24px;
      font-family: 'Rubik-Medium';
      font-size: 18px;
      line-height: 24px;      
      display: flex;
      align-items: center;
      text-align: center;
      text-transform: capitalize;     
      color: #FFFFFF;  
      margin: 0px 28px 0px 24px;
      @media (max-width: 280px){
        width: auto;
      }
    }
  }
  }
}
`;

const RatingWrapperr = styled.div`
  width:100%;
  max-width: 1200px;
  padding : 150px 0px 0px 0px;
  margin-left: auto;
  margin-right: auto;
  // margin-top: 70px;
  @media(max-width:1000px){
    padding: 0;
  }
  @media(max-width: 700px){
    padding-top: 67px;
  }
`;

const RowWrapperV3 = styled.div`
  width: 100%;
  padding: ${({selected}) => selected ? '25px 20px' : '15px 20px'};
  background-color: ${({selected, bgColor}) => selected ? bgColor : '#fff'};
  display: flex;
  flex-direction: column;
  border-radius: 7px;
  cursor: pointer;
  margin-bottom: 10px;
  .header{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  >img{
  width: 30px;
  height: 30px;
  margin-right: 10px;
  cursor: pointer;
  }
  >div{
  width: calc(100% - 70px);
  font-size: 22px;
  font-family: ${({customColor}) => customColor ? `${BoldFont}`: `${MediumFont}`} ;
  color: #24395A;

  >h3{
    margin:0;
  }
  >p{
    margin:0;
  }
  }
  .downArrow{
  width: 20px;
  transform: ${({selected}) => selected && 'rotateX(180deg)'};
  >img{
  width: 14px;
  cursor: pointer;
  }
  }
  }
  .expand{
  padding: 20px 50px 0;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 26px;
  font-family: ${MontserratRegular};;
  font-style: normal;
  color: #3A3A3A;
  cursor: pointer;
  }
  @media(max-width:500px){
  display:none;
  }
`;

const RowResponsiveWrapperV3 = styled.div`
  display: block;
  width: 100%;
  padding: ${({selected}) => selected ? '25px 20px' : '15px 20px'};
  background-color: ${({selected, bgColor}) => selected ? bgColor : '#fff'};
  flex-direction: column;
  border-radius: 7px;
  cursor: pointer;
  margin-top: 15px;
  .header{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    >img{
      width: 35px;
      height: 40px;
      margin-right: 10px;
      cursor: pointer;
    }
    >div{
      width: calc(100% - 70px);
      font-size: 24px;
      font-family: ${({customColor}) => customColor ? `${BoldFont}`: `${MediumFont}`};
      color: #24385A;
    }
    .downArrow{
      width: 20px;
      transform: ${({selected}) => selected && 'rotateX(180deg)'};
      >img{
        width: 14px;
        cursor: pointer;
      }
    }
  }
  .expand{
    padding: 20px 30px 0 50px;
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 26px;
    font-family: ${MontserratRegular};
    cursor: pointer;
    @media(max-width: 700px){
      padding: 20px 0px 0 0px;
    }
    @media(max-width: 435px){
      font-size:15px;
      
    }
  }
  @media(max-width: 500px){
    display: flex;
    .header{
      >div{
      font-size: 22px;
      }
    }
  }
`;

const DemoText = styled.div`
 font-family: Rubik;
 font-size: 18px;
 line-height: 20px;
 text-align:left;
 margin-left:75px;
 width:90%;
 color: white;
 margin-bottom:40px;
 @media(max-width:500px){
  width:100%
  text-align:center;
  margin-left:0px;
 }
`;

const WellBeing = styled.div`
  width:100%;
  max-width: 1200px;
  display:flex;
  height: 400px;
  padding: 0 40px 0p 40px;
  background-repeat: no-repeat;
  border-radius: 12px;
  justify-content: center;
  margin: 150px auto 70px auto;
  @media(max-width: 500px){
    display: none;
  }
  @media(max-width: 780px){
    display: none;
  }
  >div{
    width:50%;
    display: flex;
    align-items: center;
    justify-content:flex-end;
    height: 400px;
  }
  >div:first-child{
    justify-content:flex-start;
    height: 400px;
    background: ${({background})=>background ? `${background}` : `#F6B479`};
    border-radius: 16px 0px 0px 16px;
    display: block;
    @media(max-width: 500px){
      display: none;
    }
    >div:first-child{
    >p{
      left: 258px;
      display: flex;
      font-family: ${BoldFont};
      font-style: normal;
      font-size: 42px;
      line-height: 49px;
      color: #FFFFFF;
      margin-bottom: 30px;
      padding: 61px 0px 30px 75px;
      @media(max-width: 500px){
        display: none;
      }
    }
  }
}
>div:last-child{
  display: flex;
  border-radius: 0px 16px 16px 0px;
  background: ${({background})=>background ? `${background}` : `#F6B479`};
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;
    @media(max-width: 500px){
      display: none;
    }
    >img{
      width: auto;
      height: 370px;
      position: relative;
      top: 24px;
    }
}
@media (max-width: 500px){
  width:100%;
  display: grid;
  height: 400px;
  padding: 0 80px 0p 80px;
  background-repeat: no-repeat;
  border-radius: 12px;
  justify-content: center;
  margin: 160px auto 160px auto;
  margin-bottom: 500px
  >div{
    width:100%;
    display: flex;
    align-items: center;
    justify-content:flex-end;
    height: 300px;
  }
  >div:first-child{
    width: 100%;
    justify-content:flex-start;
    height: 300px;
    background: #F6B479;
    display: block;
    >div:first-child{
    >p{
      left: 258px;
      display: flex;
      font-family: ${BoldFont};
      font-style: normal;
      font-size: 36px;
      line-height: 40px;
      color: #FFFFFF;
      margin-bottom: 30px;
      padding: 61px 0px 30px 75px;
      width: 100%;
      padding: 50px 0px 11px;
      text-align: center;
    }
  }
}
>div:last-child{
  height: 400px;
  display: flex;
  background: #F6B479;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;
    top: -2px;
    position: relative;
    >img{
      width: 350px;
    height: auto;
    position: relative;
    top: 65px;
    }
}
}
@media (max-width: 780px){
  width:100%
  display: grid;
  height: 400px;
  padding: 0 80px 0p 80px;
  background-repeat: no-repeat;
  border-radius: 12px;
  justify-content: center;
  margin: 40px auto 160px auto;
  margin-bottom: 150px
  >div{
    width:100%;
    display: flex;
    align-items: center;
    justify-content:flex-end;
    height: 300px;
  }
  >div:first-child{
    width: 100%;
    justify-content:flex-start;
    height: 300px;
    background: #F6B479;
    display: block;
    max-width: 750px;
    width: 700px;
    min-width: 500px;
    >div:first-child{
    >p{
      left: 258px;
      display: block;
      font-family: ${BoldFont};
      font-style: normal;
      font-size: 36px;
      line-height: 40px;
      color: #FFFFFF;
      margin-bottom: 30px;
      padding: 61px 0px 30px 92px;
      width: 100%;
      padding: 50px 0px 11px;
      text-align: center;
    }
  }
}
>div:last-child{
  height: 260px;
  display: flex;
  background: #F6B479;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;
    top: -2px;
    position: relative;
    >img{
      width: 315px;
    height: auto;
    position: relative;
    top: 10px;
    }
}
}
`;

const ButtonGetStarted = styled.button`
  width: ${(props) => props.tryFree ? '200px' : '330px'};
  background-color: ${(props) => props.tryFree ? '#fff' : '#253A5B' };
  color: ${(props) => props.tryFree ? '#1573B9' : '#fff' };
  display: ${({display}) => display}; 
  padding: ${(props) => props.paddingVertical ? `${props.paddingVertical}` : '15px 0px'};
  border-radius: 3px;
  border: none;
  font-size: 18px;
  font-family: Rubik-Medium;
  font-style: normal;
  margin-left: 75px;
  cursor: ${({ disabled }) => disabled ? 'not-allowed !important' : 'pointer'};
  margin-bottom: 20px;
  &:hover, &:focus, &:active {
    outline: none;
  }
  
  @media (max-width: 1000px) {
   margin-top: ${(props) => props.tryFree ? props.marginTop : '35px'};
  }
  @media (max-width: 780px) {
    margin: auto;
  }
  @media (max-width: 500px) {
    width: 315px;
    margin: auto;
  }
`;


export{ButtonWatchVideo, ButtonLearnMoree, TabData, Button2,Section, IntroTextContainer, PosterImage,ResourcesWrapper,RowWrapperV2,MainResources,Resources,RowResponsiveWrapperV2,VideoButtonV1, CoreFeatureSectionV1, TextImagesContainer, /*TabImagesContainer,*/  CoreWrapper, ButtonGetStartedd, WellBeingg, CoreListt, CustomListt, MenuLinkssV1, MenuListV2Main3, TabsContainerV3, TopCard, RatingWrapperr, ResourcesV2, CoreFeatureSectionV2, RowWrapperV3, RowResponsiveWrapperV3, DemoText, WellBeing, ButtonGetStarted };